@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Russo+One&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap'); 
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~@ng-select/ng-select/themes/default.theme.css";
/** Custom Scrollbar Styles **/
@layer base {
  /* For Webkit browsers (Chrome, Safari, etc) */
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    
  }

  ::-webkit-scrollbar-track {
    background: hsl(var(--background));
    border-radius: 50% !important;
  }

  ::-webkit-scrollbar-thumb {
    background: hsl(var(--primary));
    border-radius: 50%;
    transition: background 0.2s ease;

    &:hover {
      background: hsl(var(--muted-foreground));
    }
  }


  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border border-border bg-background px-3 py-3 text-xs text-foreground placeholder-muted-foreground focus:z-10 focus:border-primary focus:outline-none focus:ring-primary;
    padding-right: 0px !important;
    padding-block: 0px !important; 
    min-height: 48px !important;
    font-size: 12px !important;
    
    
    // RTL specific styles
    &:lang(ar), &[dir="rtl"] {
      font-family: var(--font-tajawal) !important;
      padding-inline: 12px !important; // Slightly more padding for Arabic text
      text-align: right;


    }

    // LTR specific styles
    &:lang(en), &[dir="ltr"] {
      font-family: var(--font-inter) !important;
      text-align: left;
    }
  }
  /* For Firefox */
  * {
    scrollbar-width: none;
    scrollbar-color: hsl(var(--primary)) hsl(var(--background));
  }

  /* Dark mode overrides */
  .dark {
    ::-webkit-scrollbar-track {
      background: hsl(var(--background));
    }

    ::-webkit-scrollbar-thumb {
      background: hsl(var(--primary));

      &:hover {
        background: hsl(var(--muted-foreground));
      }
    }

    * {
      scrollbar-color: hsl(var(--muted)) hsl(var(--background));
    }
  }

  :root {
    --background: 0 0% 100%;
    --background-sidebar: 211 100% 50%;
    --background-navbar: 0 0% 98%; 
    --foreground: 210deg 10.34% 22.75%;
    --card: 210 40% 96%;
    --card-foreground: 240 0% 0%;
    --primary: 347 77% 50%;
    --primary-foreground: 0 0% 100%;
    --chart-secondary:160 100% 45%;
    --chart-third:39.56deg 99.13% 54.71%;
    --chart-forth:159.65deg 100% 44.51%;
    --chart-primary:0 100% 84%;
    --chart-third:261 51% 51%;
    --muted: 217 33% 38%;
    --muted-foreground: 215 16% 47%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 0 0% 98%;
    --border: 214 32% 91%;
    --focus-color: 211.01deg 100% 65.1%;
    --light-color: 214 20% 69%; 
    --custom-gray: 231 28% 95%; 
    --custom-gray-hover: 0 0 95%; 
    --font-inter: 'Inter', sans-serif;
    --font-tajawal: 'Tajawal', sans-serif;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 95%;
    --card: 0 0% 7%;
    --card-foreground: 0 0% 14.9%;
    --primary: 347 77% 50%;
    --primary-foreground: 0 0% 100%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
  }

  :root[data-theme='violet'] {
    --primary: 262.1 83.3% 57.8%;
  }

  .dark[data-theme='violet'] {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 222 47% 7%;
    --card-foreground: 210 20% 98%;
    --primary: 263.4 70% 50.4%;
    --primary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
  }

  :root[data-theme='red'] {
    --primary: 350 100% 40%;
  }

  .dark[data-theme='red'] {
    --primary: 350 100% 40%;
    --primary-foreground: 0 85.7% 97.3%;
  }

  :root[data-theme='blue'] {
    --primary: 211 100% 50%;
  }

  .dark[data-theme='blue'] {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222 47% 7%;
    --card-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
  }

  :root[data-theme='orange'] {
    --primary: 24.6 95% 53.1%;
  }

  .dark[data-theme='orange'] {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 24 10% 7%;
    --card-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --border: 12 6.5% 15.1%;
  }

  :root[data-theme='yellow'] {
    --primary: 47.9 95.8% 53.1%;
  }

  .dark[data-theme='yellow'] {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 24 10% 7%;
    --card-foreground: 60 9.1% 97.8%;
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
  }

  :root[data-theme='green'] {
    --primary: 142.1 76.2% 36.3%;
  }

  .dark[data-theme='green'] {
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border border-border bg-background px-3 py-3 text-xs text-foreground placeholder-muted-foreground focus:z-10 focus:border-primary focus:outline-none focus:ring-primary;
    padding-right: 0px !important;
    padding-block: 0px !important; 
    min-height: 48px !important;
    font-size: 12px !important;
    
    
    // RTL specific styles
    &:lang(ar), &[dir="rtl"] {
      font-family: var(--font-tajawal) !important;
      padding-right: 12px; // Slightly more padding for Arabic text
      text-align: right;
      background-position: left 0.5rem center !important;

    }

    // LTR specific styles
    &:lang(en), &[dir="ltr"] {
      font-family: var(--font-inter) !important; 
      padding-left: 12px ;
      text-align: left;
    }
  }

  [type='checkbox'],
  [type='radio'] {
    @apply h-4 w-4 rounded border-border bg-background text-primary focus:ring-2 focus:ring-primary;
  }

  // Custom date input styles
  input[type="date"] {
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    &::-webkit-datetime-edit {
      color: #6B7280;
      padding: 0;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      padding: 0;
    }

    &::-webkit-datetime-edit-text {
      padding: 0 0.2em;
    }

    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      padding: 0 0.2em;
    }

    &:focus {
      &::-webkit-datetime-edit {
        color: #1F2937;
      }
    }

    &:invalid {
      &::-webkit-datetime-edit {
        color: #9CA3AF;
      }
    }
  }

  [dir="rtl"] {
    input[type="date"] {
      &::-webkit-calendar-picker-indicator {
        left: auto;
        right: 0;
      }
    }
  }

  /* Default language-specific text */
  .text-ar {
    font-family: var(--font-tajawal);
  }

  .text-en {
    font-family: var(--font-inter);
  }

  /* Automatic language detection */
  *:lang(ar) {
    font-family: var(--font-tajawal);
  }

  *:lang(en) {
    font-family: var(--font-inter);
  }

  body {
    font-size: clamp(14px, 1vw, 16px);
  }

  input,
  select,
  textarea {
    font-size: clamp(12px, 0.9vw, 14px) !important;
  }

  label {
    font-size: clamp(13px, 0.95vw, 15px);
  }

  button {
    font-size: clamp(12px, 0.9vw, 14px);
  }

  // Make these rules more specific and forceful
  input,
  select,
  textarea,
  .form-input,
  .form-select,
  .form-textarea {
    &:where([dir="rtl"]),
    &:where(:lang(ar)) {
      font-family: var(--font-tajawal) !important;
    }

    &:where([dir="ltr"]),
    &:where(:lang(en)) {
      font-family: var(--font-inter) !important;
    }
  }
}

@layer components {
  .dropdown-content {
    @apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
  }

  .dropdown:hover>.dropdown-content {
    @apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
  }

  .sidebar-tablet {
    @apply flex-col items-center justify-start w-full gap-0.5;
  }

  .toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-[2.5px] bg-white border border-gray-300 rounded-full h-[13.5px] w-[13.5px] transition shadow-sm;
  }

  input:checked+.toggle-bg:after {
    transform: translateX(100%); 
    @apply border-white left-2;
  }

  input:checked+.toggle-bg {
    @apply bg-[#007BFF] border-[#007BFF];
  }

  .custom-input{
    @apply bg-white border border-[#B8C1CC] h-[50px] rounded-[10px] placeholder:text-[#A0AEC0];
  }
}

/** Scroll bar **/
@supports selector(::-webkit-scrollbar) {
  .supports-scrollbars\: pr-2 {
    padding-right: 0.5rem;
  }
}

/** Plugins **/
@import './assets/styles/apexchart.scss';

// Custom date picker styles
.custom-date-picker {
  position: relative;
  
  input[type="date"] {
    @apply appearance-none w-full px-3 py-2 border border-customGray rounded-md focus:outline-none focus:ring-1 focus:ring-primary text-gray-600;
    
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: transparent;
      background: transparent;
      cursor: pointer;
    }

    &::-webkit-datetime-edit {
      @apply text-gray-500;
      font-size: 0.875rem;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      @apply bg-transparent;
      padding: 0;
    }

    &::-webkit-datetime-edit-text {
      @apply text-gray-400;
      padding: 0 0.2em;
    }

    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      @apply text-gray-700;
      padding: 0 0.2em;
    }

    &:focus {
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-year-field {
        @apply text-primary bg-primary/5;
        border-radius: 2px;
      }
    }

    &::-webkit-inner-spin-button {
      display: none;
    }

    &::-webkit-clear-button {
      display: none;
    }
  }

  // Calendar icon
  .calendar-icon {
    @apply absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-400;
    [dir="rtl"] & {
      @apply right-auto left-3;
    }
  }

  // Hover and focus states
  &:hover {
    input[type="date"] {
      @apply border-primary/30 bg-gray-50;
    }
    .calendar-icon {
      @apply text-primary/70;
    }
  }

  &:focus-within {
    input[type="date"] {
      @apply border-primary ring-1 ring-primary/20 bg-white;
    }
    .calendar-icon {
      @apply text-primary;
    }
  }

  .ngx-datepicker-input {
    @apply appearance-none w-full px-3 py-2 border border-customGray rounded-md 
           focus:outline-none focus:ring-1 focus:ring-primary text-gray-600
           bg-white cursor-pointer;
  }

  &:hover {
    .ngx-datepicker-input {
      @apply border-primary/30 bg-gray-50;
    }
    .calendar-icon {
      @apply text-primary/70 cursor-pointer;
    }
  }

  &:focus-within {
    .ngx-datepicker-input {
      @apply border-primary ring-1 ring-primary/20 bg-white;
    }
    .calendar-icon {
      @apply text-primary;
    }
  }
}

// Calendar popup styles
.mat-datepicker-popup {
  @apply rounded-lg shadow-xl border border-gray-200;
}

.mat-calendar {
  @apply rounded-lg;
}

// RTL support
[dir="rtl"] {
  .custom-date-picker {
    input[type="date"] {
      &::-webkit-calendar-picker-indicator {
        left: auto;
        right: 0;
      }
    }
  }
}

@import "~@ng-select/ng-select/themes/default.theme.css";
// or if you prefer material design:
// @import "~@ng-select/ng-select/themes/material.theme.css";

// Make ng-select dropdown appear above dialog
.ng-dropdown-panel {
  z-index: 1500 !important; // Adjust this value if needed, should be higher than your dialog's z-index
}

// Optional: If you want to style the dropdown container
.ng-select.ng-select-opened .ng-select-container {
  z-index: 1500 !important;
}

.ng-select {
  height: 50px !important;
  .ng-select-container {
    height: 100% !important;
    border: none !important;
    background-color: transparent !important; 
    .ng-value-container{
      align-items: center !important;
      .ng-placeholder, .ng-value {
        font-size: 12px !important;
      }
    }
  }
   .ng-clear-wrapper{
    z-index: 1000 !important;
   }
}
// [dir="rtl"]{
//   .ng-select{
//     .ng-select-container{
//       .ng-value-container{
//         align-items: center !important;
//         align-content: center !important;
//       }
//     }
//   }
// }

/* Add these CSS rules */
[lang="ar"],
*:lang(ar),

*[dir="rtl"]:not(svg, svg *, i.material-icons, span.apexcharts-legend-text) {
  font-family: 'Tajawal','Inter', sans-serif !important;
} 
[lang="en"],
*:lang(en),
*[dir="ltr"]:not(svg, svg *, i.material-icons, span.apexcharts-legend-text) {
  font-family: 'Inter','Tajawal', sans-serif !important;
}

span.apexcharts-legend-text{
  font-family: 'Inter','Tajawal', sans-serif !important;
}


/* For elements containing Arabic text but not explicitly marked */
*:is(:not([lang])):matches(:has(*:lang(ar)), :has(*[dir="rtl"])):not(svg, svg *, i.material-icons) {
  font-family: 'Tajawal','Inter', sans-serif !important;
}

*:is(:not([lang])):matches(:has(*:lang(en)), :has(*[dir="ltr"])):not(svg, svg *, i.material-icons) {
  font-family: 'Inter','Tajawal', sans-serif !important;
}
.material-icons{
  font-family: 'Material Icons' !important;
}

/* Select base styles with custom scrollbar */
.select-base {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6;
  
  /* Show scrollbar for options */
  & > option {
    padding: 8px 12px;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }
}

/* Custom scrollbar for dropdowns and select menus */
.scrollable-dropdown {
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    
    &:hover {
      background: #555;
    }
  }
}

/* Style for ng-select dropdowns */
.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    max-height: 250px !important;
    @extend .scrollable-dropdown;
  }
}